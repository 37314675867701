<template>
  <div>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col>
            <h2>
              ສິນຄ້າທັງໝົດ:
              <span class="primary-color">({{ count }})</span>
            </h2>
          </v-col>
          <v-col>
            <v-text-field
              outlined
              dense
              clearable
              label="ຄົ້ນຫາ ດ້ວຍລະຫັດ, ຊື່"
              type="text"
              v-model="search"
              @keyup.enter="Search()"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row justify="end">
          <template>
            <v-btn
              class="btn-primary cart-button"
              style="display: inline-block"
              fab
              medium
              dark
              @click="openCheckout()"
            >
              <v-icon class="cart-icon">mdi-cart </v-icon>
              <span
                class="main-color count"
                v-if="cart != null && cart.length > 0"
              >
                {{ cart.length }}
              </span>
            </v-btn>
          </template>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="products"
          :disable-pagination="true"
          hide-default-footer
          class="elevation-1"
          :loading="TableLoading"
          :disabled="TableLoading"
          loading-text="Loading... Please wait"
        >
          <template v-slot:item.amount="{ item }">
            <v-chip :color="getColor(item.amount)">{{ item.amount }}</v-chip>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn
              class="btn-primary"
              fab
              small
              dark
              @click="OpenModalAdd(item)"
            >
              <v-icon>mdi-cart</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <br />
        <Pagination
          v-if="pagination.last_page > 1"
          :pagination="pagination"
          :offset="offset"
          @paginate="fetchData()"
        ></Pagination>

        <ModalAdd>
          <template @close="close">
            <v-card>
              <v-card-title>
                <span class="headline">Add Product To Cart</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-form ref="form" lazy-validation>
                    <v-row>
                      <v-col cols="12">
                        <h3>{{ addItem.name }}</h3>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <v-text-field
                          label="ຈຳນວນສິນຄ້າ *"
                          required
                          v-model="addItem.qty"
                          type="number"
                          class="input-number"
                          :rules="productCodeRules"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          label="ລາຄາສິນຄ້າ"
                          type="number"
                          class="input-number"
                          v-model="addItem.purchasePrice"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="CloseModalAdd()">
                    Close
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    :loading="loading"
                    :disabled="loading"
                    @click="AddItem()"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card-text>
            </v-card>
          </template>
        </ModalAdd>

        <!--Edit Modal-->

        <!--
        <ModalEdit>
          <template @close="close">
            <v-card>
              <v-card-title>
                <span class="headline">Update Product</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-form ref="form" lazy-validation>
                    <v-row>
                      <v-col cols="6">
                        <v-text-field
                          label="ລະຫັດສິນຄ້າ *"
                          required
                          v-model="product_edit.barcode"
                          :rules="productCodeRules"
                        ></v-text-field>
                        <p class="errors">
                          {{ server_errors.barcode }}
                        </p>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          label="ຊື່ສິນຄ້າ *"
                          required
                          v-model="product_edit.name"
                          :rules="nameRules"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-select
                          required
                          v-model="product_edit.categoryId"
                          :items="categories"
                          item-text="name"
                          item-value="id"
                          label="ປະເພດສິນຄ້າ *"
                          :rules="ruleCatetory"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="6">
                        <v-select
                          required
                          v-model="product_edit.unitId"
                          :items="units"
                          item-text="unit"
                          item-value="id"
                          label="ຫົວໜ່ວຍ *"
                          :rules="ruleUnit"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          label="ລາຍລະອຽດ"
                          v-model="product_edit.description"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="CloseModalEdit()">
                    Close
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    :loading="loading"
                    :disabled="loading"
                    @click="UpdateItem()"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card-text>
            </v-card>
          </template>
        </ModalEdit>
        -->

        <!--Delete Modal-->
        <ModalDelete>
          <template>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn
                color="blue darken-1"
                text
                :loading="loadingView"
                :disabled="loadingView"
                @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </template>
        </ModalDelete>
        <!-- View Modal -->

        <ModalView>
          <template @close="close">
            <v-card mdi-map-marker-circle>
              <v-card-title>
                <span class="headline">Checkout</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-form ref="form" lazy-validation>
                    <v-row>
                      <v-col cols="6">
                        <v-autocomplete
                          v-model="selectedUser"
                          :items="users"
                          item-text="name"
                          item-value="id"
                          label="ຜູ້ຈັດຊື້"
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="6">
                        <v-autocomplete
                          v-model="selectedSupplier"
                          :items="suppliers"
                          item-text="first_name"
                          item-value="id"
                          label="ຜູ້ສະໜອງສິນຄ້າ *"
                          :rules="supplierRule"
                        >
                        </v-autocomplete>
                      </v-col>

                      <v-col cols="6">
                        <v-menu
                          v-model="start_menu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="purchase_date"
                              label="ວັນທີຊື້"
                              readonly
                              outlined
                              v-bind="attrs"
                              v-on="on"
                              dense
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="purchase_date"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="6">
                        <v-select
                          required
                          outlined
                          dense
                          v-model="selectedPayment"
                          :items="payments"
                          item-text="payment"
                          item-value="id"
                          label="ການຊຳລະ *"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          label="ສ່ວນຫຼຸດເປັນເງິນ"
                          v-model="purchase.discount_price"
                          type="number"
                          class="input-number"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          label="ອາກອນເປັນເປີເຊັນ"
                          v-model="purchase.vat"
                          type="number"
                          class="input-number"
                        ></v-text-field>
                      </v-col>
                      <!--
                      <v-col cols="12">
                        <v-text-field
                          label="ລາຄາຊື້ເຂົ້າ"
                          v-model="product"
                          :rules="priceRule"
                          type="number"
                          class="input-number"
                        ></v-text-field>
                      </v-col>
-->
                      <v-col cols="12">
                        <v-text-field
                          label="ລາຍລະອຽດ"
                          v-model="purchase.note"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                  <v-row>
                    <v-col cols="12">
                      <h3>ລາຍລະອຽດສິນຄ້າ</h3>
                    </v-col>
                  </v-row>
                  <v-card>
                    <v-card-text>
                      <v-row>
                        <v-col cols="4">
                          <div class="customer">ລາຍການ</div>
                        </v-col>
                        <v-col cols="2">
                          <div class="customer">ຈຳນວນ</div>
                        </v-col>
                        <v-col cols="2">
                          <div class="customer">ລາຄາ</div>
                        </v-col>
                        <v-col cols="2">
                          <div class="customer">ລາຄາລວມ</div>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>

                  <v-card v-for="(item, index) in cart" :key="index" outlined>
                    <v-card-text>
                      <v-row>
                        <v-col cols="4">
                          <div class="customer">
                            {{ index + 1 }} {{ item.name }}
                          </div>
                        </v-col>
                        <v-col cols="2">
                          <div class="customer">{{ item.qty }}</div>
                        </v-col>
                        <v-col cols="2">
                          <div class="customer">
                            {{ formatPrice(item.purchasePrice) }}
                          </div>
                        </v-col>
                        <v-col cols="2">
                          <div class="customer">
                            {{ formatPrice(item.qty * item.purchasePrice) }}
                          </div>
                        </v-col>
                        <v-col cols="2">
                          <!-- <v-icon small class="mr-4"> mdi-pencil </v-icon> -->
                          <v-icon
                            small
                            class="mr-2"
                            @click="deletePurchaseItem(index)"
                          >
                            mdi-delete
                          </v-icon>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                  <br />
                  <v-card outlined>
                    <v-card-text>
                      <v-row>
                        <v-col cols="4">
                          <div class="customer">
                            ຈຳນວນລາຍການ: {{ totalItem() }}
                          </div>
                        </v-col>
                        <v-col cols="3"> </v-col>
                        <v-col cols="3">
                          <div class="customer">
                            ລາຄາລວມ: {{ formatPrice(sumTotal()) }}
                          </div>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>

                  <!-- 
              <v-row>
                <v-col cols="6">
                  <p class="invoice">
                    ເບີໂທ:
                    <span class="customer">{{ customerDetail.phone }}</span>
                  </p>
                </v-col>
                <v-col cols="6">
                  <p class="invoice">
                    Email:
                    <span class="customer">{{ customerDetail.email }}</span>
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <p class="invoice">
                    ປະເພດລູກຄ້າ:
                    <span class="customer">{{
                      customerDetail.customer_type
                    }}</span>
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <p class="invoice">
                    ທີ່ຢູ່:
                    <span class="customer"
                      >{{ customerDetail.village }},
                      {{ customerDetail.district }},
                      {{ customerDetail.proName }} [{{
                        customerDetail.address
                      }}]</span
                    >
                  </p>
                </v-col>
                <v-col cols="6">
                  <p class="invoice">
                    ທີ່ມາຂອງລູກຄ້າ:
                    <span class="customer">{{
                      source(customerDetail.source)
                    }}</span>
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <p class="invoice">
                    ຍອດສະສົມຕຸກນ້ຳ:
                    <span class="customer error--text">{{
                      customerDetail.accumulated_purchase_number
                    }}</span>
                  </p>
                </v-col>
                <v-col cols="6">
                  <p class="invoice">
                    ຄະແນນສະສົມ:
                    <span class="customer error--text">{{
                      customerDetail.point
                    }}</span>
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" v-if="customerDetail.package">
                  <p class="invoice">
                    ປະເພດແພັກເກດ:
                    <span class="primary--text"
                      >{{ customerDetail.package.name }}
                      ລາຄາຕໍ່ຕຸກ
                      {{ customerDetail.package.price }}</span
                    >
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <p class="invoice">
                    Created By:
                    <span class="customer">{{
                      customerDetail.created_by
                    }}</span>
                  </p>
                </v-col>
                <v-col cols="6">
                  <p class="invoice">
                    ສະຖານະ:
                    <span :class="getstatusColor(customerDetail.status)">{{
                      customerDetail.status
                    }}</span>
                  </p>
                </v-col>
              </v-row> -->
                </v-container>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="warning darken-1"
                    class="mr-6"
                    text
                    @click="closeModalView()"
                  >
                    Close
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    :loading="loading"
                    :disabled="loading"
                    @click="Checkout()"
                  >
                    ຈັດຊື້
                  </v-btn>
                </v-card-actions>
              </v-card-text>
            </v-card>
          </template>
        </ModalView>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import purchase_product from "@/mixins/warehouse/purchase_product";
import Pagination from "@/plugins/pagination/pagination";

export default {
  mixins: [purchase_product],
  components: {
    Pagination,
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
/*@import "../../../../../public/scss/main.scss";*/
.customer {
  color: #000;
  font-size: 18px;
}
.invoice {
  font-size: 18px;
}
.primary-color {
  color: #00c1d2;
}
.main-color {
  color: #d2002e !important;
}
.text-field {
  border-color: #00c1d2;
  padding: 12px 8px 12px 8px;
  width: 100%;
  margin-bottom: 12px;
  font-size: 16px;
}
.cart-button {
  display: block !important;
  padding: 12px 0px 12px 0px !important;
}
.count {
  margin-top: -16px;
  margin-bottom: -4px;
  margin-right: -3px;
  font-size: 22px;
}
.cart-icon {
  margin-top: 8px;
}
.v-btn__content {
  display: inline-block !important;
}
</style>
