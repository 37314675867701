import { GetOldValueOnInput } from "@/Helpers/GetValue";
export default {
  data() {
    return {
    purchase_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    start_menu: false,

      loading: false,
      loadingView:false,
      TableLoading: false,
      products:[],
      categories:[],
      units:[],
      suppliers:[],
      users:[],
      selectedUser:'',
      selectedSupplier:'',
      selectedPayment:'cash',
      productId: "",
      purchase:{
        discount_price:0,
        vat:0
      },
      payments:[
        {id:'cash',
      payment:'ເງິນສົດ'
      },
      {id:'bank',
      payment:'ທະນາຄານ'
      },
      {id:'debt',
      payment:'ຕິດໜີ້'
      },
      {id:'support',
      payment:'ສະໜັບສະໜູນ'
      },
      ],
 
      addItem:{
       qty:1,
       purchasePrice:0,

      },
      cart:[],
      product: {},
      count:0,
      product_edit: {},

      //Pagination
      offset: 15,
      pagination: {},
      per_page: 100,
      oldVal: "",
      search:'',
      //Location
  // Service-Zone
  checkedServiceZone: [],
      unitValue: "",
      headers: [
        {
          text: "ລະຫັດສິນຄ້າ",
          align: "start",
          value: "barcode",
          sortable: false
        },
        {
          text: "ຊື່ສິນຄ້າ",
          align: "start",
          value: "name",
        },
        {
          text: "ປະເພດ",
          align: "start",
          value: "category",sortable: false
        },
        { text: "ຈຳນວນ", value: "amount", },
        { text: "ຫົວໜ່ວຍ", value: "unit",sortable: false },
        // { text: "Email", value: "email" },
        // { text: "ລາຍລະອຽດ", value: "description" },
        // { text: "ລາຍລະອຽດທີ່ຢູ່", value: "address" },
        // { text: "ຜູ້ສະໜອງ", value: "created_by" },
        // { text: "ຜູ້ຈັດຊື້", value: "created_by" },
        // { text: "Created By", value: "created_by" },

        // { text: "ວັນທີ", value: "createdDate" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      productCodeRules: [
        (v) => !!v || "Product amount is required",
      ],

      priceRule: [
        (v) => !!v || "Price is required",
        (v) =>
          (v && v.length >= 4 && v.length <= 11) ||
          "Price number must be  4 - 11 numbers",
      ],
      supplierRule: [
        (v) => !!v || "Supplier is required",
      ],
      toast: {
        value: true,
        color: "success",
        msg: "Success",
      },
      toast_error: {
        value: true,
        color: "error",
        msg: "Something when wrong!",
      },
    alreadyExits: {
        value: true,
        color: "warning",
        msg: "ສິນຄ້ານີ້ໄດ້ເພີ່ມເຂົ້າກະຕ່າແລ້ວ! ກາລະລຸນາກວດສອບຄືນ",
      },
    };
  },
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed().replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    //Checkout
    Checkout() {
      this.purchase.supplier_id = this.selectedSupplier;
      this.purchase.purchase_by = this.selectedUser;
      this.purchase.payment_type = this.selectedPayment;
      this.purchase.purchase_date = this.purchase_date;
      this.purchase.cart = this.cart;
      if (this.$refs.form.validate() == true) {
        this.loading = true;
        this.$admin
          .post("warehouse/add-purchase",this.purchase)
          .then((res) => {
            if (res.data.success == true) {
              setTimeout(() => {
                this.loading = false;
                this.closeModalView();
                this.purchase.note = '';
                this.selectedUser = '';
                localStorage.removeItem('purchaseItem');
                window.location.reload();
                this.cart=[]
                this.reset();
                this.fetchData();
                this.$store.commit("Toast_State", this.toast);
              }, 300);
            }
          })
          .catch((error) => {
            this.loading = false;
            this.$store.commit("Toast_State", this.toast_error);
            this.fetchData();
            if (error.response.status == 422) {
              var obj = error.response.data.errors;
              for (let [key, product] of Object.entries(obj)) {
                this.server_errors[key] = product[0];
              }
            }
          });
      }
    },

    OpenModalAdd(item) {
   if(this.cart != null && this.cart.length > 0 ){
    const products = this.cart;
    const index = products.findIndex(ite => ite.id === item.id);
    if(index !== -1){
      // (index)
      this.$store.commit("Toast_State", this.alreadyExits);
      // const product = this.cart[index];
      // (product)
      // const qty = parseInt(product.qty) + parseInt(this.qty);
      // (qty);
      // this.UpdateCart(product.id, qty);
  }else{
    this.addItem = item;
    this.addItem.qty = 1;
    this.addItem.purchasePrice = 0;
    this.$store.commit("modalAdd_State", true);
  }
   }else {
    this.addItem = item;
    this.addItem.qty = 1;
    this.addItem.purchasePrice = 0;
    this.$store.commit("modalAdd_State", true);
   }
  //  this.addItem.amount = '';
    },

  FilterCart(id){
    return this.products.filter(item => {
        return item.id == id;
    })[0] || {};
},
UpdateCart(id, qty){
  return this.cart.filter(item => {
      if(item.id === id){
          return item.qty = qty;
      }
  })[0] || {};
},

    AddItem() {
      if (this.$refs.form.validate() == true) {
        this.SubmitItem();
      }
    },
    SubmitItem() {
    var a = [];
    a = JSON.parse(localStorage.getItem('purchaseItem')) || [];
    a.push(this.addItem);
    localStorage.setItem('purchaseItem', JSON.stringify(a));
    this.getPurchseItem();
    this.addItem.qty = 1;
    this.addItem.purchasePrice = 0;
    this.$store.commit("Toast_State", this.toast);
    this.CloseModalAdd();
  
    },
    sumTotal () {
     if(this.cart != null){
      return this.cart.reduce(function (total, item) {
        total += item.purchasePrice * item.qty;
        return total;
      }, 0);
     }
    },
    totalItem: function () {
      if(this.cart != null){
        return this.cart.length;
      }
    },
    getPurchseItem(){
      const data = localStorage.getItem('purchaseItem');
      this.cart = JSON.parse(data);
      this.$emit('itemData', this.cart);
      
  },

    CloseModalAdd() {
      this.product = {};
      this.reset();
      this.fetchData();
      this.$store.commit("modalAdd_State", false);
    },

    OpenModalEdit(item) {
      this.product_edit = item;
      this.$store.commit("modalEdit_State", true);
    },

    openCheckout() {
      if(this.cart != null && this.cart.length > 0){
        this.$store.commit("modalView_State", true);
      }
 
    },
    closeModalView() { this.loadingView = false;
      this.$store.commit("modalView_State", false);
    },
    deletePurchaseItem(index) {
      this.cart.splice(index,1);
      localStorage.setItem('purchaseItem',JSON.stringify(this.cart));
      // // this.showModalConfirm = false;
      // localStorage.removeItem('purchaseItem');
      this.getPurchseItem();
      if(this.cart.length == 0){
        this.$store.commit("modalView_State", false);
      }
},
    UpdateItem() {
      this.product_edit.category_id = this.product_edit.categoryId;
      this.product_edit.unit_id = this.product_edit.unitId;
      (this.product_edit);
      if (this.$refs.form.validate() == true) {
        this.loading = true;
        this.$admin
          .post("warehouse/update-product/" + this.product_edit.id, this.product_edit)
          .then((res) => {
            if (res.data.success == true) {
              setTimeout(() => {
                this.loading = false;
                this.CloseModalEdit();
                this.product_edit = {};
                this.reset();
                this.fetchData();
                this.$store.commit("Toast_State", this.toast);
              }, 300);
            }
          })
          .catch((error) => {
            this.loading = false;
            this.$store.commit("Toast_State", this.toast_error);
            this.fetchData();
            if (error.response.status == 422) {
              var obj = error.response.data.errors;
              for (let [key, product] of Object.entries(obj)) {
                this.server_errors[key] = product[0];
              }
            }
          });
      }
    },

    CloseModalEdit() {
      this.product_edit = {};
      this.fetchData();
      this.reset();
      this.$store.commit("modalEdit_State", false);
    },

    fetchData() {
      this.TableLoading = true;
      this.$admin.get('warehouse/products',{
        params:{
            page: this.pagination.current_page,
            per_page: this.per_page,
            search: this.search
        }
    }).then(res => {
        setTimeout(() => {
          this.products = res.data.products.data;
          this.pagination = res.data.products;
          this.count = res.data.products.total
          this.TableLoading =false;
        }, 500);
      }).catch(() => {
        this.TableLoading =false;
        this.$store.commit("Toast_State",this.toast_error);
      });

       //Fetch Supplier
          this.$admin
            .get("list-supplier")
            .then((res) => {
              this.suppliers = res.data.suppliers;
              // (this.suppliers);
            })
            .catch(() => {
            });
                this.$admin.get('list-department').then(res => {
                  setTimeout(() => {
                    this.users = res.data.department;
                    this.TableLoading =false;
                  }, 100);
                }).catch(() => {
                  this.TableLoading =false;
                  this.$store.commit("Toast_State",this.toast_error);
                });
    },

    closeDelete() {
      this.$store.commit("modalDelete_State", false);
    },

    deleteItem(id) {
      this.productId = id;
      this.$store.commit("modalDelete_State", true);
    },

    deleteItemConfirm() {
      this.loading = true;
      this.$admin
        .delete("warehouse/delete-product/" + this.productId)
        .then((res) => {
          if (res.data.success == true) {
            setTimeout(() => {
              this.fetchData();
              this.$store.commit("modalDelete_State", false);
              this.loading = false;
              this.$store.commit("Toast_State", this.toast);
            }, 300);
          }
        })
        .catch(() => {
          this.$store.commit("Toast_State",this.toast_error);
          this.fetchData();
          this.$store.commit("modalDelete_State", false);
          this.loading = false;
        });
    },
    reset() {
      this.$refs.form.reset();
    },

///Search
Search() {
  GetOldValueOnInput(this);
},

    //Get Round color
    getColor(amount) {
      if (amount == 0) return "error";
      else return "success";
    },
  },
  watch: {
    "product.barcode": function () {
      this.server_errors.barcode = "";
    },
    "product_edit.barcode": function () {
      this.server_errors.barcode = "";
    },
      'search': function(value){
          if(value == ''){
              this.fetchData();
          }
  }
  },

  created() {
    this.fetchData();
    this.getPurchseItem();
  },
};
